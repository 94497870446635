<template>
  <div>
    <b-form-group
      label="Título"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-title"
        v-model="$v.survey.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-description"
        v-model="$v.survey.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div class="col" style="text-align: left">
        <b-button
          v-if="show_delete_button && !isNaN(survey.id)"
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LikertAssertionForm",
  mixins: [validationMixin],
  props: {
    Survey: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      survey: {
        id: this.Survey ? this.Survey.id : generateUniqueId(),
        title: this.Survey ? this.Survey.title : "",
        description: this.Survey ? this.Survey.description : "",
      },
    };
  },
  validations() {
    return {
      survey: {
        title: { required },
        description: {},
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.survey[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.survey.$touch();
      if (this.$v.survey.$anyError) {
        return;
      }
      if (isNaN(this.survey.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful.Post("/cideu/survey/", this.survey).then((response) => {
        this.survey = response;
        this.$emit("created", response);
        toast("Encuesta creada.");
      });
    },
    update() {
      this.$restful
        .Put(`/cideu/survey/${this.survey.id}/`, this.survey)
        .then((response) => {
          this.survey = response;
          this.$emit("updated", response);
          toast("Encuesta actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Encuesta?",
        description: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful.Delete(`/cideu/survey/${this.survey.id}/`).then(() => {
        this.$emit("deleted", this.survey);
        toast("Encuesta eliminada.");
      });
    },
  },
};
</script>

<style>
</style>